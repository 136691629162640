import React, {useEffect, useState, useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {Input, ListaDesplegable} from '../../inputs'
import {BotonSimple} from '../../Botones'
import CampoFiltrarEspañol from '../../CampoFiltrarEspañol'
import {
  AgregarEmpresasACache,
  ObtenerEmpresasPorNitONombre,
  ObtenerTodasLasSedes,
} from '../../../cache/servicios-cache'
import {useModal} from '../../../hooks/useModal'
import {ContextApplication} from '../../../contexto'
import {useHistory} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import {RUTAS_APP} from '../../../constantes'
import {ROLES_APP} from '../../../constantes'
import {ObtenerConfiguracionPorParticion} from '../../../microservicios/Reporte'
import {CrearSolicitudReporte} from '../../../microservicios/Reporte'
import {ObtenerSedePorId} from '../../../cache/servicios-cache'

const defaultValues = {
  fechaInicio: '',
  fechaFinal: '',
  sede: '',
  empresa: '',
  tipoReporte: '',
}

const FormularioGeneradorDeReportes = () => {
  const [cerrarModal] = useModal()
  const history = useHistory()
  const contextoApp = useContext(ContextApplication)

  const {accounts} = useMsal()
  const rolUsuario = accounts[0].idTokenClaims.roles[0]

  const correoUsuario = accounts[0]?.username

  const nombreUsuario = accounts[0].idTokenClaims.name

  const {
    trigger,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: {errors, isValid},
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
  })

  const empresaSeleccionadaPorDefecto = {
    empresaPadreId: '',
    id: '',
    nombre: '',
    nit: '',
    telefono: '',
    direccion: '',
    observaciones: '',
    tipoFacturacion: '',
    correo: '',
    filtro: '',
  }

  const [sedes, setSedes] = useState([])

  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(
    empresaSeleccionadaPorDefecto
  )
  const [empresasFiltradas, setEmpresasFiltradas] = useState([])
  const [fechaMinima, setFechaMinima] = useState('')
  const [fechaMaxima, setFechaMaxima] = useState('')

  const [datosTableStorage, setDatosTableStorage] = useState([])

  const [camposAMostrar, setCamposAMostrar] = useState([])

  useEffect(() => {
    let componenteMontado = true
    trigger()
    const obtenerDatosIniciales = async () => {
      await AgregarEmpresasACache()
      const todasLasSedes = await ObtenerTodasLasSedes()
      setSedes(todasLasSedes)
    }
    if (componenteMontado) {
      obtenerDatosIniciales()
    }

    return () => {
      componenteMontado = false
    }
  }, [])

  useEffect(() => {
    const ObtenerDatosReportes = async () => {
      const datosTableStorage = await ObtenerConfiguracionPorParticion()
      setDatosTableStorage(datosTableStorage?.data)
    }
    ObtenerDatosReportes()
  }, [])

  const TEXTO_TIPO_REPORTES = {
    1: 'Detalle HC',
    2: 'Rips',
    3: 'Laboratorio',
    4: 'Atención detalle',
    5: 'Detalle medios de pago',
    6: 'Contabilidad',
    7: 'Resumen por entidad',
    8: 'Detalle de atención por paciente',
    9: 'Audiometría',
    10: 'Reporte SVE',
  }

  const manejarEmpresaCambiada = async (e) => {
    if (e.target.value === '') {
      setEmpresaSeleccionada(empresaSeleccionadaPorDefecto)
    } else {
      setEmpresaSeleccionada({
        ...empresaSeleccionada,
        filtro: e.target.value,
      })
    }
    setValue('empresa')
    if (e.target.value.length >= 3) {
      let empresas
      if (isNaN(e.target.value)) {
        empresas = await ObtenerEmpresasPorNitONombre('', e.target.value)
      } else {
        empresas = await ObtenerEmpresasPorNitONombre(e.target.value, '')
      }
      setEmpresasFiltradas(
        empresas.map((empresa) => ({
          id: empresa.Id,
          filtro: empresa.Nit + '-' + empresa.Nombre.toUpperCase(),
          empresaPadreId: empresa.EmpresaPadreId,
          nit: empresa.Nit,
          observaciones: empresa.Observaciones,
          direccion: empresa.Direccion,
          nombre: empresa.Nombre,
          tipoFacturacion: empresa.TipoFacturacion.toUpperCase(),
          telefono: empresa.Telefono,
          corre: '',
        }))
      )
    } else {
      setEmpresasFiltradas([])
    }
  }
  const manejarEmpresaSeleccionada = (empresaSeleccionada) => {
    setEmpresaSeleccionada({
      empresaPadreId: empresaSeleccionada.empresaPadreId,
      id: empresaSeleccionada.id,
      nombre: empresaSeleccionada.filtro,
      nit: empresaSeleccionada.nit,
      filtro: empresaSeleccionada.filtro,
      telefono: empresaSeleccionada.telefono,
      direccion: empresaSeleccionada.direccion,
      observaciones: empresaSeleccionada.observaciones,
      tipoFacturacion: empresaSeleccionada.tipoFacturacion.toUpperCase(),
      correo: '',
    })
    setValue('empresa', empresaSeleccionada.filtro)
    trigger()
  }

  const manejarCrearReporte = async (data) => {
    contextoApp.setIsLoading(true)
    const payload = {
      nombreReporte: TEXTO_TIPO_REPORTES[data?.tipoReporte],
      CorreoSolicitante: correoUsuario,
      NombreSolicitante: nombreUsuario,
      parametros: {
        sede: data?.sede
          ? (await ObtenerSedePorId(parseInt(data?.sede)))?.Nombre
          : '',
        sedeId: data?.sede ?? '',
        empresaId: empresaSeleccionada?.id ? empresaSeleccionada?.id : '',
        fechaDesde: data?.fechaInicio,
        fechaHasta: data?.fechaFinal,
      },
    }

    try {
      await CrearSolicitudReporte(payload)
      contextoApp.setModal({
        abierto: true,
        titulo: (
          <span className="font-bold">El reporte será enviado a su correo</span>
        ),
        contenido: '',
        botones: [
          {
            nombre: 'Regresar a Gestión de reportes',
            click: () => {
              history.push(
                RUTAS_APP.ADMINISTRADOR_GATO.GESTION_DE_REPORTES_NUEVO
              )

              cerrarModal()
            },
          },
          {
            nombre: 'Nuevo reporte',
            click: () => {
              history.go(0)
              cerrarModal()
            },
          },
          {
            nombre: 'Finalizar',
            click: () => {
              history.push('/main')
              cerrarModal()
            },
          },
        ],
      })
    } catch (error) {
      console.log(error)
    } finally {
      contextoApp.setIsLoading(false)
    }
  }

  const TIPO_DE_REPORTES = [
    {
      Id: 1,
      Nombre: 'Detalle HC',
      ROLES: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.FACTURADOR,
        ROLES_APP.LICITADOR,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
    {
      Id: 2,
      Nombre: 'Rips',
      ROLES: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
    {
      Id: 3,
      Nombre: 'Laboratorio',
      ROLES: [
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.AUDITOR,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
    {
      Id: 4,
      Nombre: 'Atención detalle',
      ROLES: [
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.FACTURADOR,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.LICITADOR,
        ROLES_APP.AUDITOR,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
    {
      Id: 5,
      Nombre: 'Detalles medios de pago',
      ROLES: [
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.FACTURADOR,
        ROLES_APP.GERENTE_COMERCIAL,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.AUDITOR,
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
    {
      Id: 6,
      Nombre: 'Contabilidad',
      ROLES: [
        ROLES_APP.AUXILIAR_CONTABLE,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.ADMINISTRADOR_CONTABLE,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
    {
      Id: 7,
      Nombre: 'Resumen por entidad',
      ROLES: [ROLES_APP.GERENTE_GENERAL, ROLES_APP.AUDITOR],
    },
    {
      Id: 8,
      Nombre: 'Detalle de atención por paciente',
      ROLES: [ROLES_APP.GERENTE_GENERAL, ROLES_APP.AUDITOR],
    },
    {
      Id: 9,
      Nombre: 'Reporte audiometrías',
      ROLES: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
    {
      Id: 10,
      Nombre: 'Informe SVE',
      ROLES: [
        ROLES_APP.ADMINISTRADOR,
        ROLES_APP.JEFE_SEDE,
        ROLES_APP.EJECUTIVO_COMERCIAL,
        ROLES_APP.AUXILIAR_ADMINISTRATIVO,
        ROLES_APP.GERENTE_GENERAL,
        ROLES_APP.PROFESIONAL_SST,
        ROLES_APP.GERENTE_FINANCIERO,
      ],
    },
  ]

  const ObtenerDatosMenu = () => {
    const menu = TIPO_DE_REPORTES.filter((elemento) =>
      elemento.ROLES.includes(rolUsuario)
    )

    return menu ?? []
  }

  const limpiarFormulario = () => {
    reset()
    setCamposAMostrar([])
    setEmpresaSeleccionada(empresaSeleccionadaPorDefecto)
  }

  const validarFechas = (valor, fechaInicio) => {
    if (fechaInicio) {
      setValue('fechaInicio', valor)
      setFechaMinima(valor)
    } else {
      setValue('fechaFinal', valor)
      setFechaMaxima(valor)
    }
    trigger()
  }

  const validarCamposAMostrar = (valor) => {
    if (valor) {
      const tipoReporte = TEXTO_TIPO_REPORTES[valor]
      const reporteSeleccionado = datosTableStorage?.find(
        (element) => element.Reporte === tipoReporte
      )
      const ordenPropiedades = ['EmpresaId', 'NombreSede', 'Reporte', 'SedeId']

      const objetoOrganizado = {}

      ordenPropiedades.forEach((propiedad) => {
        if (reporteSeleccionado.hasOwnProperty(propiedad)) {
          objetoOrganizado[propiedad] = reporteSeleccionado[propiedad]
        }
      })

      return setCamposAMostrar(objetoOrganizado ?? [])
    }
    setCamposAMostrar([])
  }

  useEffect(() => {
    trigger()
  }, [camposAMostrar])

  const generarCampos = (nombreCampo, valor) => {
    if (nombreCampo === 'EmpresaId') {
      return (
        <Controller
          name="empresa"
          control={control}
          rules={{
            required: valor,
            onChange: (e) => manejarEmpresaCambiada(e),
          }}
          render={() => (
            <CampoFiltrarEspañol
              estilosPersonalizados={'w-1/4'}
              titulo={`Empresa ${valor ? '*' : ''}`}
              estilosInput={
                Object.keys(errors).find((element) => element === 'empresa')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
              }
              tipo={'text'}
              placeholder={'Autocompletar'}
              valorDelCampoFiltro={empresaSeleccionada.filtro}
              informacionFiltrada={empresasFiltradas}
              desactivarOtroFiltro={false}
              handleChange={manejarEmpresaCambiada}
              handleOptionChange={(e) => manejarEmpresaSeleccionada(e)}
            />
          )}
        />
      )
    }
    if (nombreCampo === 'SedeId') {
      return (
        <Controller
          name="sede"
          control={control}
          rules={{
            required: valor,
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-1/4 mx-10'}
              estilosLista={
                Object.keys(errors).find((element) => element === 'sede')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm'
              }
              titulo={`Sede ${valor ? '*' : ''}`}
              opciones={sedes}
              valor={value}
            />
          )}
        />
      )
    }
  }

  return (
    <form
      className="w-full flex justify-center items-center flex-wrap"
      onSubmit={handleSubmit(manejarCrearReporte)}
    >
      <div className="w-full flex justify-evenly items-center">
        <Controller
          name="tipoReporte"
          control={control}
          rules={{
            required: true,
            onChange: (e) => validarCamposAMostrar(e.target.value),
          }}
          render={({field: {onChange, value}}) => (
            <ListaDesplegable
              onChange={onChange}
              estilosContenedor={'w-1/4 '}
              estilosLista={
                Object.keys(errors).find((element) => element === 'tipoReporte')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              titulo={'Tipo de reportes*'}
              opciones={ObtenerDatosMenu()}
              valor={value}
            />
          )}
        />
        <Controller
          name="fechaInicio"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {value}}) => (
            <Input
              estilosContenedor="w-1/4"
              estilosInput={
                Object.keys(errors).find((element) => element === 'fechaInicio')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              mostrarErrores={true}
              tipo={'date'}
              titulo={'Fecha de inicio*'}
              valor={value}
              fechaMaxima={fechaMaxima}
              onChange={(e) => validarFechas(e.target.value, true)}
            />
          )}
        />
        <Controller
          name="fechaFinal"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {value}}) => (
            <Input
              estilosContenedor="w-1/4"
              estilosInput={
                Object.keys(errors).find((element) => element === 'fechaFinal')
                  ? 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra-rojo-1 text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm '
                  : 'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
              }
              tipo={'date'}
              titulo={'Fecha de fin*'}
              valor={value}
              fechaMinima={fechaMinima}
              onChange={(e) => validarFechas(e.target.value)}
            />
          )}
        />
      </div>

      {Object.entries(camposAMostrar).map(
        ([nombreCampo, valor]) =>
          nombreCampo !== 'Reporte' && generarCampos(nombreCampo, valor)
      )}
      <div className="w-full flex justify-center items center mt-10">
        <BotonSimple
          texto={'Generar'}
          estilosBoton={`w-24 h-8 text-white ${
            isValid
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }  bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
          funcion={null}
          tipoDeBoton={'submit'}
        />
        <BotonSimple
          texto={'Limpiar'}
          estilosBoton={`w-24 h-8 text-white ${
            true
              ? 'bg-btnBg'
              : 'bg-cendiatra-gris-placeholder pointer-events-none'
          }  bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
          funcion={limpiarFormulario}
        />
      </div>
      <div className="w-full flex items-center justify-center mt-5">
        <BotonSimple
          texto={'Regresar'}
          estilosBoton={`w-24 h-8 text-white bg-btnBg bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
          funcion={() => {
            history.push(RUTAS_APP.ADMINISTRADOR_GATO.GESTION_DE_REPORTES_NUEVO)
          }}
        />
      </div>
    </form>
  )
}

export default FormularioGeneradorDeReportes
